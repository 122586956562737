import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Modal } from "../../../components/Modal";
import { Text, Col } from "../../../styles/Common";
import { Radio } from "../../../components/Radio";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { validateResetPassRequest } from "../../../utilities/validations";
import useSendEmailOtp from "../hooks/useSendEmailOtp";
import useSendSmsOtp from "../hooks/useSendSmsOtp";
import { setTokenRequested } from "../../../core/store/auth";

const ModalResetPassword = ({
  showModal,
  handleCloseModal,
  setShowModalOTP,
  isMobile,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validateResetPassRequest),
    defaultValues: {
      ind: 1,
      recovery: "email",
    },
  });
  const dispatch = useDispatch();

  const sendEmailOtp = useSendEmailOtp();
  const sendSmsOtp = useSendSmsOtp();

  const recoveryMethod = watch("recovery");

  const handleRequestOtp = (data) => {
    const action = sendEmailOtp;
    const dataToSend = { email: data.email, isPasswordReset: true };

    action.reset();
    action.mutate(dataToSend, {
      onSuccess: (res) => {
        dispatch(
          setTokenRequested({
            recoveryMethod: recoveryMethod,
            dataToSend: dataToSend,
            token: res?.data?.result,
            phoneResetPassword: null,
          })
        );
        handleCloseModal();
        setShowModalOTP(true);
      },
      onError: (err) => {
        toast.error(
          err?.response?.data?.title || err?.response?.data || err?.message
        );
      },
    });
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      height="auto"
      width={!isMobile ? "611px" : "100%"}
    >
      <Modal.Header
        title={"Recupera tu cuenta"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 5px 0"}
      />
      <Modal.Body>
        <Text size={theme.fonts.size.h6} color={theme.colors.gray200} mb="39px">
          Para proceder con la recuperación de la cuenta, es necesario obtener y
          enviar un código de verificación.
        </Text>
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h6}
          color={theme.colors.gray300}
          mb="24px"
        >
          ¿Dónde deseas recibir el código?
        </Text>

        <form
          onSubmit={handleSubmit(handleRequestOtp)}
          style={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Controller
            name="recovery"
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                value={field.value}
                gap={!isMobile ? "48px" : "12px"}
                error={errors?.recovery}
                helper={errors?.recovery?.message}
              >
                <Radio
                  value="email"
                  label={"Correo electrónico"}
                  color={theme.colors.gray300}
                />
              </Radio.Group>
            )}
          />
          {recoveryMethod === "email" && (
            <Col align="center">
              <Text mt="19px" mb="13px" color={theme.colors.gray300}>
                Indica tu correo electrónico
              </Text>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Correo electrónico"
                    color={theme.colors.gray300}
                    background={theme.colors.white}
                    width={!isMobile ? "388.5px" : "100%"}
                    border={theme.colors.gray200}
                    error={errors?.email}
                    helper={errors?.email?.message}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value.toLowerCase());
                    }}
                  />
                )}
              />
            </Col>
          )}
          <Button
            width={"165px"}
            htmlType="submit"
            m="32.22px 0 0 0"
            disabled={!isValid}
            loading={sendEmailOtp.isLoading}
          >
            Solicitar código
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResetPassword;
