import React, { useEffect, useState } from "react";
import { PageWrapperPlatform } from "./styles";
import { theme } from "../../styles/theme";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "../../components/Link";
import { Div, Text } from "../../styles/Common";
import logo from "../../assets/images/logo.svg";
import Tabs from "../../components/Tabs";
import { deleteLocalUser } from "../../core/services/axiosInstance";
import { getUserInfo } from "../../utilities/helpers";
import useGetValidateIsFilledProfile from "./hooks/useGetValidateIsFilledProfile";
import ModalValidateAccount from "./components/ModalValidateAccount";
import ModalSuccess from "./components/ModalSucces";
import { LogoutIcon } from "../../assets/icons";
import { MyAccount } from "./components/MyAccount";
import { EditMyAccount } from "./components/EditMyAccount";
import { Tooltip } from "antd";
import { HomePriv } from "./components/HomePriv";
import { useOutletContext } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { DrawerMobile } from "./components/DrawerMobile";
import ModalOTPValidateAccount from "./components/ModalOTPValidateAccount";

export const PlatForm = () => {
  const navigate = useNavigate();
  const { isMobile } = useOutletContext();

  const { nameid, email, unique_name, LastName } = getUserInfo();
  const panels = [
    {
      tab: "Inicio",
      key: "1",
    },
    {
      tab: "Mi cuenta",
      key: "2",
    },
  ];
  const handleTabChange = (key) => {
    setEditMyAccount(false);
    setActiveKey(key);
  };

  const initials =
    unique_name && LastName
      ? `${unique_name.charAt(0)}${LastName.charAt(0)}`.toUpperCase()
      : "NN";

  const getValidateIsFilledProfile = useGetValidateIsFilledProfile(nameid);
  const { data, isSuccess } = getValidateIsFilledProfile;
  useEffect(() => {
    if (isSuccess) {
      if (!data?.data?.result.isAccountValidated) {
        setShowModalValidateAccount(true);
      } else if (
        data?.data?.result.isAccountValidated &&
        !data?.data?.result.isFilledProfile
      ) {
        navigate("/profileInfo");
      }
    }
  }, [data]);

  const [showModalValidateAccount, setShowModalValidateAccount] =
    useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [activeKey, setActiveKey] = useState("2");
  const [editMyAccount, setEditMyAccount] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleCloseModal = () => {
    setShowModalValidateAccount(false);
    setShowModalOTP(false);
    setShowModalSuccess(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const handleCloseSesion = () => {
    deleteLocalUser();
    toast.remove();
    navigate("/login");
  };

  return (
    <PageWrapperPlatform>
      <ModalValidateAccount
        showModal={showModalValidateAccount}
        handleCloseModal={handleCloseModal}
        setShowModalOTP={setShowModalOTP}
        name={unique_name}
        email={email}
        isMobile={isMobile}
        handleCloseSesion={handleCloseSesion}
      />
      <ModalOTPValidateAccount
        showModalOTP={showModalOTP}
        handleCloseModal={handleCloseModal}
        name={unique_name}
        email={email}
        setShowModal={setShowModalSuccess}
        isMobile={isMobile}
        handleCloseSesion={handleCloseSesion}
      />
      <ModalSuccess
        showModal={showModalSuccess}
        handleCloseModal={handleCloseModal}
        isMobile={isMobile}
      />
      <DrawerMobile
        showDrawer={showDrawer}
        handleCloseDrawer={handleCloseDrawer}
        setActiveKey={setActiveKey}
        initials={initials}
        name={
          unique_name?.split(" ")[0].length > 15
            ? `${unique_name?.split(" ")[0].substring(0, 15)}...`
            : unique_name?.split(" ")[0]
        }
        lastName={
          LastName?.split(" ")[0].length > 15
            ? `${LastName?.split(" ")[0].substring(0, 15)}...`
            : LastName?.split(" ")[0]
        }
        handleCloseSesion={handleCloseSesion}
        setEditMyAccount={setEditMyAccount}
      />

      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={!isMobile ? "28px 98px" : "17px"}
        align="center"
        background={theme.colors.blackBlur1}
      >
        {isMobile && (
          <MenuOutlined
            style={{ color: theme.colors.gray200, fontSize: "30px" }}
            onClick={() => setShowDrawer(true)}
          />
        )}
        <img src={logo} alt="logo" width={"154.65px"} height={"35.33px"} />
        {!isMobile && (
          <Div>
            <Tabs
              defaultActiveKey="2"
              onChange={handleTabChange}
              activeKey={activeKey}
              size={theme.fonts.size.h6}
              gutter={67}
            >
              {panels.map((panel) => (
                <Tabs.Panel tab={panel.tab} key={panel.key} />
              ))}
            </Tabs>
          </Div>
        )}
        <Div align="center" justify="space-around" gap="8px">
          <Div
            height="44px"
            width="44px"
            style={{ minWidth: "44px" }}
            background={theme.colors.red}
            radius="50px"
            align="center"
            justify="center"
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h6}
            >
              {initials}
            </Text>
          </Div>
          {!isMobile && (
            <>
              <Text
                color={theme.colors.white}
                weight={theme.fonts.weight.semibold}
                size={theme.fonts.size.h6}
              >
                {unique_name?.split(" ")[0].substring(0, 15)}{" "}
                {LastName?.split(" ")[0].substring(0, 15)}
              </Text>
              <Link onClick={handleCloseSesion}>
                <Tooltip title="Cerrar sesión">
                  <LogoutIcon stroke={theme.colors.white} />
                </Tooltip>
              </Link>
            </>
          )}
        </Div>
      </Div>
      {activeKey === "1" && <HomePriv name={unique_name} isMobile={isMobile} />}
      {activeKey === "2" && !editMyAccount && (
        <MyAccount setEditMyAccount={setEditMyAccount} isMobile={isMobile} />
      )}
      {activeKey === "2" && editMyAccount && (
        <EditMyAccount
          setEditMyAccount={setEditMyAccount}
          isMobile={isMobile}
        />
      )}
    </PageWrapperPlatform>
  );
};
