import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { AlertIcon } from "../../../assets/icons";
import useSendEmailOtp from "../../Login/hooks/useSendEmailOtp";
import { setVerificationTokens } from "../../../core/store/verificationCodes";

const ModalValidateAccount = ({
  showModal,
  handleCloseModal,
  setShowModalOTP,
  email,
  name,
  isMobile,
  handleCloseSesion,
}) => {
  const sendEmailOtp = useSendEmailOtp();
  const dispatch = useDispatch();

  const handleContinue = () => {
    const emailAction = sendEmailOtp;

    const emailData = { email: email, userName: name, isPasswordReset: false };

    let tokenEmail = null;

    const handleSuccess = (res, type) => {
      if (type === "email") {
        tokenEmail = res?.data?.result;
      }
    };

    const requests = [];

    if (emailAction) {
      emailAction.reset();
      requests.push(
        emailAction.mutateAsync(emailData, {
          onSuccess: (res) => handleSuccess(res, "email"),
          onError: (err) => {
            toast.error(err?.response?.data?.title || err?.response?.data);
          },
        })
      );
    }

    Promise.all(requests)
      .then(() => {
        dispatch(
          setVerificationTokens({
            tokenEmail: tokenEmail,
          })
        );
        handleCloseModal();
        setShowModalOTP(true);
      })
      .catch((err) => {});
  };

  return (
    <Modal
      open={showModal}
      onCancel={() => {
        handleCloseSesion();
        handleCloseModal();
      }}
      padding={"29px 63px 37px 63px"}
      width={!isMobile ? "499px" : "100%"}
      height={!isMobile ? "418px" : "auto"}
    >
      <Modal.Header
        title={"A la espera de validación"}
        sizeTitle={!isMobile ? theme.fonts.size.h1 : theme.fonts.size.h3}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 27px 0"}
        icon={<AlertIcon stroke={theme.colors.red} />}
        iconBackground={theme.colors.white50}
        iconBorderColor={theme.colors.red}
        style={{ lineHeight: "50px" }}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          weight={theme.fonts.weight.semibold}
          mb="32px"
        >
          Para continuar, debes finalizar tu registro y realizar la validación
          de tu cuenta
        </Text>

        <Button
          width={!isMobile ? "217px" : "170px"}
          onClick={handleContinue}
          loading={sendEmailOtp.isLoading}
          style={{ alignSelf: "center" }}
        >
          Validar mi cuenta
        </Button>
      </Modal.Body>
    </Modal>
  );
};
ModalValidateAccount.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  setShowModalOTP: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string,
  ind: PropTypes.number,
  phone: PropTypes.string,
  optionsCodes: PropTypes.array,
  isMobile: PropTypes.bool,
  handleCloseSesion: PropTypes.func,
};
export default ModalValidateAccount;
